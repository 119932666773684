exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-auth-login-callback-jsx": () => import("./../../../src/pages/auth/login-callback.jsx" /* webpackChunkName: "component---src-pages-auth-login-callback-jsx" */),
  "component---src-pages-auth-logout-callback-jsx": () => import("./../../../src/pages/auth/logout-callback.jsx" /* webpackChunkName: "component---src-pages-auth-logout-callback-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-preisliste-jsx": () => import("./../../../src/pages/preisliste.jsx" /* webpackChunkName: "component---src-pages-preisliste-jsx" */),
  "component---src-pages-pureme-family-jsx": () => import("./../../../src/pages/pureme-family.jsx" /* webpackChunkName: "component---src-pages-pureme-family-jsx" */),
  "component---src-pages-workshops-jsx": () => import("./../../../src/pages/workshops.jsx" /* webpackChunkName: "component---src-pages-workshops-jsx" */)
}

